.flex {
  display: flex;
}

.w-full {
  width: 100%;
}

.wrapper-out {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}

.wrapper-in {
  max-width: 113.75rem;
  margin: 0 auto;
}

@media (width <= 1024px) {
  .wrapper-out {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

@media (width <= 768px) {
  .wrapper-out {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

@media (width <= 480px) {
  .wrapper-out {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.the-bg-img {
  z-index: 0;
  width: 100vw;
  height: 100dvh;
  position: absolute;
}
/*# sourceMappingURL=index.af030ff5.css.map */
